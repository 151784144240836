function ButtonGradientTL({ title }: { title: any }) {
    return (
        <div className="button-hover-topLeft relative">
            <span className="topLeft-top absolute bg-green-gradient90 h-11 w-full -top-2 -left-2 rounded-xl"></span>
            <button className='topLeft-bottom relative z-10 h-11 font-bold lg:font-semibold text-sm border border-solid border-black rounded-xl px-10'>
                {title}
            </button>
        </div>
    );
}

export default ButtonGradientTL;
