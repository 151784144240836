
function MembershipCard({ item }: { item: any }) {
    let black = `<b>${item.black}</b>`;
    let description = item.black !== "" ? item.description.replace(item.black, black) : item.description
    return (
        <div className="relative w-full lg:w-5/6">
            <div className='relative flex flex-col justify-center items-center border border-solid border-black rounded-2xl px-5 bg-white h-28 z-10'>
                <div className='text-sm leading-normal' dangerouslySetInnerHTML={{ __html: description }} />
            </div>
            <div className="w-full h-28 absolute top-3 left-3 bg-black rounded-2xl"></div>
        </div>
    );
}

export default MembershipCard;
