import ButtonBorderBR from "./ButtonBorderBR";
import ButtonGradientBR from "./ButtonGradientBR";

function MembershipTypeCard({ item }: { item: any }) {
    return (
        <div className='relative w-full col-span-1 mx-10 lg:mx-0 mb-5 lg:mb-0'>
            {
                item.type === "PYMES" ? <div className="w-full h-full bg-green-gredient180 absolute top-3 left-3 rounded-2xl"></div> : null
            }
            <div className="flex flex-col justify-center items-center py-5 border border-solid border-black rounded-2xl relative bg-white z-10">
                <p className='font-lora font-semibold text-xl mt-5'>{item.name}</p>
                <p className='text-sm font-light mt-5 mb-20'>{item.type}</p>

                <div className='w-full h-16 px-5 flex items-center justify-between lg:justify-center'>
                    <span className="w-1/6 flex justify-start lg:justify-center items-center">
                        {item.consults !== 0 && item.consults !== "NO" ? item.consults : <img src="/images/close.svg" alt="close" />}
                    </span>
                    <span className="block lg:hidden w-5/6">Consultas mensuales</span>
                </div>

                <div className='w-full h-16 px-5 py-2 lg:py-0 flex items-start lg:items-center justify-between lg:justify-center bg-neutral-100'>
                    <span className="w-1/6 flex justify-start lg:justify-center items-center pt-1 lg:pt-0">
                        {item.documents !== 0 && item.documents !== "NO" ? item.documents : <img src="/images/close.svg" alt="close" />}
                    </span>
                    <span className="block lg:hidden w-5/6">Revisiones de documentos</span>
                </div>

                <div className='w-full h-16 px-5 flex items-center justify-center'>
                    <span className="w-1/6 flex justify-start lg:justify-center items-center">
                        {item.questions !== 0 && item.questions !== "NO" ? item.questions : <img src="/images/close.svg" alt="close" />}
                    </span>
                    <span className="block lg:hidden w-5/6">Preguntas rápidas</span>
                </div>

                <div className='w-full h-16 px-5 flex items-center justify-center bg-neutral-100'>
                    <span className="w-1/6 flex justify-start lg:justify-center items-center">
                        <img src={`/images/${item.videoCalls !== 0 && item.videoCalls !== "NO" ? "check" : "close"}.svg`} alt="option" />
                    </span>
                    <span className="block lg:hidden w-5/6">Videollamadas</span>
                </div>

                <div className='w-full h-16 px-5 lg:flex lg:items-center lg:justify-center'>
                    <span className="block lg:hidden">Documentos automáticos</span>
                    <span className="font-bold lg:font-normal">{item.automaticDocuments}</span>
                </div>
                <div className='w-full h-16 px-5 py-2 lg:py-0 flex items-start lg:items-center justify-center bg-neutral-100'>
                    <span className="w-1/6 flex font-bold lg:font-normal pt-1 lg:pt-0">{item.discount}%</span>
                    <span className="block lg:hidden w-5/6">Descuento en trámites y procesos</span>
                </div>

                <div className='h-16 my-5 flex items-center'>US$ <span className="font-bold text-2xl">{item.price}</span> /mes</div>
                <div className="h-16 w-5/6">
                    {
                        item.type === "PYMES" ? <ButtonGradientBR title={item.btnText} /> : <ButtonBorderBR title={item.btnText} />
                    }
                </div>
            </div>
        </div>
    );
}

export default MembershipTypeCard;
