import { useState } from 'react';
import Button from './Button';
import { useLocation } from 'react-router-dom';

function Header({ info }: { info: any }) {
    const location = useLocation();
    const { pathname } = location;
    const withoutHeader = ["/mobile/custom"];
    const [showMenu, setShowMenu] = useState(false);
    return (
        pathname in withoutHeader ?
            <div className='w-full flex justify-center items-center border-b border-solid border-black bg-[#FAFAFA] z-10'>
                <div className='w-full max-w-screen-2xl h-16 lg:h-[85px] px-10 lg:px-20 flex justify-between lg:justify-around items-center'>
                    <div className='w-1/2 lg:w-2/12'>
                        <img src={`/images/${info.logo}.svg`} alt="logo" />
                    </div>
                    <div className='hidden lg:flex w-7/12 justify-center items-center gap-10 text-base'>
                        {
                            info.options.map((item: { link: any, title: any }) => <a key={item.title} href={item.link}>{item.title}</a>)
                        }
                    </div>
                    <div className='hidden lg:flex w-3/12 justify-center items-center gap-5'>
                        <button className='font-bold text-sm border border-solid border-transparent'>{info.login}</button>
                        <Button title={info.signIn} />
                    </div>
                    <div className='w-1/2 flex lg:hidden justify-end'>
                        <img src={`/images/menu.svg`} alt="logo" onClick={() => setShowMenu(true)} />
                    </div>
                </div>
                {
                    showMenu ?
                        <div className='block lg:hidden h-screen w-full fixed z-30 top-0 left-0'>
                            <div className='h-screen w-full bg-white bg-opacity-60 backdrop-blur-sm absolute top-0 left-0' onClick={() => setShowMenu(false)} ></div>
                            <div className='w-5/6 bg-white shadow relative z-10'>
                                <div className='px-10 pt-10 pb-5'>
                                    <img src={`/images/${info.logo}.svg`} alt="logo" />
                                    <div className='w-full flex flex-col mt-10 gap-5'>
                                        {
                                            info.options.map((item: { link: any, title: any }) => <a href={item.link} className='py-2'>{item.title}</a>)
                                        }
                                    </div>
                                </div>
                                <div className='w-full flex justify-center items-center gap-5 px-5 pb-10'>
                                    <button className='font-bold text-sm border border-solid border-transparent'>{info.login}</button>
                                    <Button title={info.signIn} />
                                </div>
                            </div>
                        </div>
                        : null
                }
            </div>
            : null
    );
}

export default Header;
