import ButtonBorderBL from "./ButtonBorderBL";

function BusinessCard({ item }: { item: any }) {
    return (
        <div className='relative w-full col-span-2 lg:col-span-1 flex flex-col justify-center items-center border border-solid border-black rounded-2xl p-5 lg:p-10 bg-white'>
            <div className="h-20 flex justify-center items-center">
                <img src={`/images/${item.img}.svg`} alt={item.img} />
            </div>
            <p className='font-lora font-semibold text-xl leading-normal mt-5'>{item.title}</p>
            <p className='text-sm mt-5 mb-10 h-20 leading-normal'>{item.description}</p>
            <ButtonBorderBL title={item.btnText} />
        </div>
    );
}

export default BusinessCard;
