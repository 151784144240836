import Slider from 'react-slick';
import * as info from '../../../components/Info';
import ServicesCard from '../../../components/ServicesCard';

function SecondSection() {
  return (
    <div className='w-full min-h-screen bg-white px-5 py-16 lg:p-28 flex flex-col justify-center items-center text-center' >
      <p className='text-sm'>-{info.sectionList[1]}-</p>
      <h2 className='w-full lg:w-5/6 font-lora font-semibold text-3xl lg:text-5xl leading-tight mt-5'>{info.secondSection.title}</h2>
      <p className='w-full lg:w-5/12 mt-10 text-sm'>{info.secondSection.description}</p>
      <div className='w-full hidden lg:grid grid-cols-3 gap-24 mt-10 relative'>
        <img src="/images/circleTL.svg" alt="circleTL" className='absolute -top-16 -left-20' />
        <img src="/images/circleBL.svg" alt="circleBL" className='absolute -bottom-16 left-[60%]' />
        <img src="/images/circleBR.svg" alt="circleBR" className='absolute -top-16 -right-20' />
        {
          info.secondSection.serviceList.map(item => <ServicesCard key={item.title} item={item} />)
        }
      </div>
      <div className='w-full block gap-10 lg:hidden mt-10 relative'>
          <Slider {...info.sliderSettingsMobile}>
            {
              info.secondSection.serviceList.map(item => <ServicesCard key={item.title} item={item} />)
            }
          </Slider>
      </div>
    </div>
  );
}

export default SecondSection;
