import Slider from 'react-slick';
import * as info from '../../../components/Info';
import OpinionCard from '../../../components/OpinionCard';

function EighthSection() {
  return (
    <div className='w-full max-w-screen-2xl min-h-screen bg-white px-5 py-16 lg:p-28 grid grid-cols-12'>
      <div className='col-span-12 lg:col-span-7 flex flex-col justify-center items-start relative'>
        <p className='w-full text-sm'>-{info.sectionList[7]}-</p>
        <h2 className='w-full lg:w-5/6 font-lora font-semibold text-3xl lg:text-5xl leading-tight mt-5'>{info.eighthSection.title}</h2>
      </div>
      <div className='col-span-12 lg:col-span-5 flex flex-col justify-center items-center'>
        <p className='w-full lg:w-2/3 mt-0 lg:mt-10'>{info.eighthSection.description}</p>
      </div>
      <div className='hidden lg:block col-span-12 gap-24 mt-10 relative'>
        <Slider {...info.sliderSettings}>
          {
            info.eighthSection.commentList.map(item => <OpinionCard key={item.name} item={item} />)
          }
        </Slider>
      </div>
      <div className='block lg:hidden col-span-12 gap-24 mt-10 relative'>
        <Slider {...info.sliderSettingsMobile}>
          {
            info.eighthSection.commentList.map(item => <OpinionCard key={item.name} item={item} />)
          }
        </Slider>
      </div>
    </div>
  );
}

export default EighthSection;
