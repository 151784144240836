
function SupportCard({ item }: { item: any }) {
    return (
        <div className='relative h-28 lg:h-36 w-full col-span-1 flex flex-col justify-center items-center border border-solid border-[#F4F4F4] bg-white px-5 lg:px-20 hover:shadow-2xl'>
            <p className='w-full text-left font-semibold text-base lg:text-xl leading-normal'>{item.title}</p>
            <p className='w-full text-left mt-3 leading-normal text-sm lg:text-base'>{item.description}</p>
        </div>
    );
}

export default SupportCard;
