import * as info from '../../components/Info';
import DocumentMeta from 'react-document-meta';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FirstSection from './components/FirstSection';
import SecondSection from './components/SecondSection';
import ThirdSection from './components/ThirdSection';
import QuarterSection from './components/QuarterSection';
import FifthSection from './components/FifthSection';
import SixthSection from './components/SixthSection';
import SeventhSection from './components/SeventhSection';
import EighthSection from './components/EighthSection';

function App() {
    return (
        <div className='w-full flex flex-col justify-center items-center'>
            <DocumentMeta {...info.meta} />

            {/* START FIRST SECTION */}
            <FirstSection />
            {/* END FIRST SECTION */}

            {/* START SECOND SECTION */}
            <SecondSection />
            {/* END SECOND SECTION */}

            {/* START THIRD SECTION */}
            <ThirdSection />
            {/* END THIRD SECTION */}

            {/* START QUARTER SECTION */}
            <QuarterSection />
            {/* END QUARTER SECTION */}

            {/* START FIFTH SECTION */}
            <FifthSection />
            {/* END FIFTH SECTION */}

            {/* START SIXTH SECTION */}
            <SixthSection />
            {/* END SIXTH SECTION */}

            {/* START SEVENTH SECTION */}
            <SeventhSection />
            {/* END SEVENTH SECTION */}

            {/* START EIGHTH SECTION */}
            <EighthSection />
            {/* END EIGHTH SECTION */}

        </div>
    );
}

export default App;
