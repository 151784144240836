import ButtonBorderBL from "./ButtonBorderBL";

function ServicesCard({ item }: { item: any }) {
    return (
        <div className='relative w-full col-span-1 flex flex-col justify-center items-center border border-solid border-black rounded-2xl p-10 bg-white mx-5 lg:mx-0 mb-5 lg:mb-0'>
            <img src={`/images/${item.img}.svg`} alt={item.img} />
            <p className='font-lora font-semibold text-xl leading-normal mt-5'>{item.title}</p>
            <p className='text-sm mt-5 mb-16 h-20 leading-normal'>{item.description}</p>
            <ButtonBorderBL title={item.btnText} />
        </div>
    );
}

export default ServicesCard;
