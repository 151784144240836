import { useLocation } from 'react-router-dom';
import ButtonGradientTL from './ButtonGradientTL';

function Footer({ info }: { info: any }) {
    const location = useLocation();
    const { pathname } = location;
    const withoutHeader = ["/mobile/custom"];
    return (
        pathname in withoutHeader ?
            <div className='w-full flex flex-col justify-center items-center bg-[#FAFAFA] z-10'>
                <div className='w-full max-w-screen-2xl py-16 px-5 lg:p-20 grid grid-cols-12'>
                    <div className='col-span-12 lg:col-span-7 flex flex-col justify-center items-start relative'>
                        <h2 className='w-full lg:w-11/12 font-lora font-semibold text-3xl lg:text-5xl leading-tight mt-5'>{info.title}</h2>
                        <p className='w-full lg:w-2/3 mt-5 lg:mt-10'>{info.description}</p>
                    </div>
                    <div className='col-span-12 lg:col-span-5 flex justify-start lg:justify-end items-center mt-10 lg:mt-0'>
                        <ButtonGradientTL title={info.btnText} />
                    </div>
                </div>
                <div className='w-full max-w-screen-2xl py-10 px-5 lg:px-20 grid grid-cols-12 border-t border-solid border-[#D8D8D8]'>
                    <div className='col-span-12 lg:col-span-4 flex flex-col justify-start items-start mb-5 lg:mb-0'>
                        <div className='w1/2 lg:w-5/12'>
                            <img src={`/images/${info.logo}.svg`} alt="logo" />
                        </div>
                        <p className='w-4/5 mt-5 lg:mt-10'>{info.subDescription}</p>
                        <div className='hidden lg:flex justify-center items-center mt-10 gap-5'>
                            {
                                info.socials.map((item: { title: any, link: any }) => <a key={item.title} href={item.link} className='w-2/3 text-sm'><img src={`/images/${item.title}.svg`} alt="logo" /></a>)
                            }
                        </div>
                    </div>
                    <div className='col-span-12 lg:col-span-5 flex flex-col justify-center items-start relative'>
                        <div className='w-full flex justify-center items-start'>
                            {
                                info.links.map((itemList: { title: any, options: any }) =>
                                    <div key={itemList.title} className='w-full'>
                                        <p className='w-full lg:w-2/3 font-lora font-bold mb-7'>{itemList.title}</p>
                                        {
                                            itemList.options.map((item: { title: any, link: any }) => <p key={item.title} className='mb-3'>
                                                <a href={item.link} className='w-2/3 text-sm leading-none'>{item.title}</a>
                                            </p>)
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className='col-span-12 lg:col-span-3 flex flex-col justify-start items-center lg:items-start relative'>
                        <div className='w-full'>
                            <p className='w-full lg:w-2/3 font-lora font-bold my-5 lg:mb-7 lg:mt-0'>{info.address.title}</p>
                            {
                                info.address.options.map((item: any) => <p key={item} className='mb-3'>
                                    <a href={item.link} className='w-2/3 text-sm'>{item}</a>
                                </p>)
                            }
                        </div>
                        <div className='w-2/3 flex lg:hidden justify-center items-center gap-5 mt-5'>
                            {
                                info.socials.map((item: { title: any, link: any }) => <a key={item.title} href={item.link} className='w-2/3 text-sm flex justify-center items-center'><img src={`/images/${item.title}.svg`} alt="logo" /></a>)
                            }
                        </div>
                    </div>
                </div>
                <div className='w-full lg:h-16 max-w-screen-2xl p-5 lg:px-20 lg:py-0 flex flex-col lg:flex-row justify-center items-center border-t border-solid border-[#D8D8D8] relative'>
                    <p className='text-xs lg:text-sm leading-none mb-3 lg:mb-0 text-center lg:text-left'>{info.copy}</p>
                    <div className='flex justify-center items-center lg:absolute right-20 gap-5'>
                        {
                            info.copyLinks.map((item: { title: any, link: any }) =>
                                <a key={item.title} href={item.link} className='text-xs lg:text-sm'>{item.title}</a>
                            )
                        }
                    </div>

                </div>
            </div >
            : null
    );
}

export default Footer;