import BusinessCard from '../../../components/BusinessCard';
import * as info from '../../../components/Info';
import MembershipCard from '../../../components/MembershipCard';

function FifthSection() {
  return (
    <div className='w-full min-h-screen max-w-screen-2xl py-16 lg:py-20 grid grid-cols-12 px-5 lg:px-28'>
      <div className='col-span-12 lg:col-span-7 flex flex-col justify-center items-start relative'>
        <p className='w-full text-sm'>-{info.sectionList[4]}-</p>
        <h2 className='w-full lg:w-2/3 font-lora font-semibold text-3xl lg:text-5xl leading-tight mt-5'>{info.fifthSection.title}</h2>
      </div>
      <div className='col-span-12 lg:col-span-5 flex flex-col justify-center items-center'>
        <p className='w-full lg:w-2/3 mt-10'>{info.fifthSection.description}</p>
      </div>
      <div className='col-span-12'>
        <div className='w-full grid grid-cols-3 mt-10 relative px-3 lg:px-0'>
          <div className='col-span-3 lg:col-span-1'>
            <div className='w-full p-5 lg:p-10'>
              <BusinessCard item={info.fifthSection.payUse} />
            </div>
          </div>
          <div className='relative col-span-3 lg:col-span-2 border border-solid border-black rounded-2xl top-5 left-3 lg:left-5'>
            <div className="w-full h-full relative lg:absolute -top-5 -left-3 lg:-left-5 grid grid-cols-2 gap-10 p-5 lg:p-10 bg-green-gredient113 rounded-2xl">
              <BusinessCard item={info.fifthSection.membership} />
              <div className='col-span-2 lg:col-span-1 flex flex-col justify-center items-center gap-5 mb-5 pr-3 lg:pr-0'>
                {
                  info.fifthSection.benefits.map(item => <MembershipCard key={item.description} item={item} />)
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FifthSection;
