import * as info from '../../../components/Info';

function SixthSection() {
  return (
    <div className='w-full max-w-screen-2xl bg-white py-16 lg:py-20 flex flex-col justify-center items-center text-center px-5 lg:px-0'>
      <p className='text-sm'>-{info.sectionList[5]}-</p>
      <h2 className='w-full lg:w-2/3 font-lora font-semibold text-3xl lg:text-5xl leading-tight mt-5'>{info.sixthSection.title}</h2>
      <p className='w-full lg:w-1/4 my-10'>{info.sixthSection.description}</p>
      <div className='w-full lg:w-9/12 h-80 lg:h-48 p-5 lg:px-28 flex flex-wrap lg:flex-nowrap justify-center items-center border border-solid border-black rounded-2xl gap-y-10 gap-x-20 lg:gap-28'>
        {
          info.sixthSection.options.map(item =>
            <div key={item.img} className='w-1/4 flex flex-col justify-center items-center text-xs lg:text-base font-semibold lg:font-normal gap-3 lg:gap-0'>
              <img src={`/images/${item.img}.svg`} alt={item.img} />
              <span>{item.text}</span>
            </div>
          )
        }
      </div>
    </div>
  );
}

export default SixthSection;
