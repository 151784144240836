
function OpinionCard({ item }: { item: any }) {
    return (
        <div className='relative w-5/6 mb-5 col-span-1 flex flex-col justify-center items-center border border-solid border-black rounded-2xl py-7 px-10 bg-white'>
            <div className="relative">
                <img src={`/images/${item.img}.jpg`} alt={item.img} className="w-20 rounded-full" />
                <img src={`/images/comment.svg`} alt="comment" className="absolute bottom-0 right-0" />
            </div>
            <p className='text-sm mt-5 h-20 text-center'>{item.description}</p>
            <hr className="w-2/3 border-[#D8D8D8] mt-3 mb-5" />
            <p className='font-lora font-semibold'>{item.name}</p>
            <p className='text-sm mt-5 text-center text-[#969696]'>{item.company}</p>
        </div>
    );
}

export default OpinionCard;
