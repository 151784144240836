import { BrowserRouter, Route, Routes } from "react-router-dom";
import Privacy from "./pages/Privacy";
import App from "./pages/App";
import MobileCustom from "./pages/MobileCustom";
import Header from "./components/Header";
import * as info from './components/Info';
import Footer from "./components/Footer";
import Terms from "./pages/Terms";

export default function RoutesApp() {
    return (
        <BrowserRouter basename="/">
            <Header info={info.header} />
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/mobile/customer/:id" element={<MobileCustom />} />
            </Routes>
            <Footer info={info.footer} />
        </BrowserRouter>

    )
}