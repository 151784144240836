import * as info from '../../../components/Info';

function QuarterSection() {
  return (
    <div className='w-full max-w-screen-2xl min-h-screen bg-white py-16 lg:py-28 flex flex-col justify-center items-center text-center'>
      <p className='text-sm'>-{info.sectionList[3]}-</p>
      <h2 className='w-full lg:w-2/3 font-lora font-semibold text-3xl lg:text-5xl leading-tight mt-5'>{info.quarterSection.title}</h2>
      <p className='w-full lg:w-1/4 my-10'>{info.quarterSection.description}</p>
      <div className='w-5/6 lg:w-[70vw] h-[50vh] lg:h-[90vh] flex justify-center items-center relative'>
        <div className={`w-full h-[50vh] lg:h-[90vh] rounded-xl bg-videoPresentation bg-cover bg-center z-10`}></div>
        <div className='w-full h-[50vh] lg:h-[90vh] border border-solid border-black absolute rounded-2xl -top-2 -left-2'></div>
      </div>
    </div>
  );
}

export default QuarterSection;
