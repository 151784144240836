
export default function Privacy() {
    return (
        <div className="py-20 px-40">
            <ol className="!list-decimal text-justify">
                <li className="font-semibold">
                    <h3 className="text-xl mb-3">¿Quienes somos?</h3>
                    <div className="font-light grid grid-cols-4 px-20">
                        <div className="col-span-1 font-semibold">País</div>
                        <div className="col-span-1 font-semibold">Nombre</div>
                        <div className="col-span-1 font-semibold">RUC</div>
                        <div className="col-span-1 font-semibold">Dirección</div>
                        <div className="col-span-1">Ecuador</div>
                        <div className="col-span-1">Moderno SAS</div>
                        <div className="col-span-1">1793191053001</div>
                        <div className="col-span-1">Shyris y Telégrafo</div>
                    </div>
                    <p className="font-light mt-3">Y cualquier otra compañía relacionada, a quienes en lo sucesivo se las denominará “las compañías”. </p>
                </li>
                <li className="font-semibold mt-10">
                    <h3 className="text-xl mb-3">¿A qué nos comprometemos por medio de esta política?</h3>
                    <p className="font-light">Nos encontramos comprometidos a cumplir con la legislación vigente en materia de protección de datos personales, la cual nos obliga a cumplir determinadas exigencias, fundamentalmente referidas a:</p> <br />
                    <ul className="!list-disc ml-10 font-light">
                        <li className="mb-3">La recopilación y el uso de datos personales (en adelante, la “Información”).</li>
                        <li className="mb-3">La calidad y la seguridad de la información.</li>
                        <li className="mb-3">Los derechos de las personas con respecto a la Información sobre sí mismos.
                            En este contexto, nos encontramos comprometidos con la protección, el manejo y el tratamiento adecuado de la Información a los que tiene acceso en la operación regular de sus negocios, ya sean datos de trabajadores, locadores de servicios, clientes, proveedores, usuarios del sitio web de las compañías, entre otros.
                            La presente política de protección de datos personales (en adelante, la “Política”) recoge las prácticas desarrolladas por las compañías para la recolección, almacenamiento y tratamiento de Información a fin de asegurar el respeto por los derechos de sus titulares, así como el cumplimiento del marco normativo vigente.
                            La Política podrá ser complementada con reglamentos corporativos o directrices adicionales que desarrollen lo establecido en el presente documento siempre que se encuentren alineadas con sus principios rectores.
                        </li>
                    </ul>
                </li>
                <li className="font-semibold mt-10">
                    <h3 className="text-xl mb-3">¿En qué ámbito aplican las disposiciones de esta política?</h3>
                    <p className="font-light mt-3">
                        El presente documento es aplicable a todas las bases de datos personales o información destinada a estar contenida en bases de datos de las compañías, así como al tratamiento de dichas bases de datos por parte de las compañías o por parte de terceros encargados. <br /> <br />
                        Todos los trabajadores de todas las empresas que conforman las compañías se encuentran obligados a conocer y cumplir todas y cada una de las disposiciones de la Política.
                    </p>
                </li>
                <li className="font-semibold mt-10">
                    <h3 className="text-xl mb-3">Definiciones </h3>
                    <p className="font-light mt-3">
                        Datos personales: toda información sobre una persona natural que la identifica o la hace identificable a través de medios que puedan ser razonablemente utilizados. <br /><br />
                        Datos sensibles: datos personales constituidos por los datos biométricos que por sí mismos pueden identificar al titular; datos referidos al origen racial y étnico; ingresos económicos; opiniones o convicciones políticas, religiosas, filosóficas o morales; pasado judicial; condición migratoria; identidad cultural; afiliación sindical e información relacionada a la salud o a la vida sexual e identidad de género. <br /><br />
                        Bases de datos personales: conjunto organizado de datos personales, automatizado o no, independientemente del soporte, sea físico, magnético, digital, óptico u otros que se creen, cualquiera fuere la forma o modalidad de su creación, formación, almacenamiento, organización y acceso. <br /><br />
                        Tratamiento de datos personales: cualquier operación o procedimiento técnico, automatizado o no, que permite la recopilación, registro, organización, almacenamiento, conservación, elaboración, modificación, extracción, consulta, utilización, bloqueo, supresión, comunicación por transferencia o por difusión o cualquier otra forma de procesamiento que facilite el acceso, correlación o interconexión de los datos personales. <br /><br />
                        Titular de datos personales: persona natural a quien corresponden los datos personales. <br /><br />
                        Titular de la base de datos: persona natural, persona jurídica de derecho privado o entidad pública que determina la finalidad y contenido de la base de datos personales, el tratamiento de estos y las medidas de seguridad. <br /><br />
                        Encargado del tratamiento: persona natural, persona jurídica de derecho privado o entidad pública que sola o actuando conjuntamente con otra realiza el tratamiento de los datos personales por encargo del titular de la base de datos personales o el responsable de tratamiento.
                    </p>
                </li>
                <li className="font-semibold mt-10">
                    <h3 className="text-xl mb-3">¿Quiénes son los responsables del cumplimiento de esta política?</h3>
                    <p className="font-light mt-3">
                        El área responsable de revisar periódicamente la Política y efectuar los ajustes respectivos dentro de las compañías sin perjuicio de ello, todos los empleados de las empresas que conforman las compañías, así como todos los terceros con quienes las compañías se vinculen en el ejercicio regular de su negocio y tengan acceso o realicen tratamiento de datos personales se encuentran sujetos al cumplimiento de la Política.
                    </p>
                </li>
                <li className="font-semibold mt-10">
                    <h3 className="text-xl mb-3">Confidencialidad</h3>
                    <p className="font-light mt-3">
                        El presente documento es de uso interno y exclusivo de las compañías por lo que tiene carácter confidencial. <br /><br />
                        Asimismo, la Información a la que los trabajadores de las compañías y/o terceros tengan acceso tiene carácter confidencial y no podrá ser divulgada, comunicada ni compartida con terceros sin contar con el consentimiento previo del titular de la Información, salvo las excepciones establecidas en la Ley. <br /><br />
                        Todas las personas que intervengan en el tratamiento de la información están obligadas a guardar el secreto profesional y a mantener la confidencialidad respecto de los mismos. Dicha obligación se mantendrá aún después de finalizada la relación que une a esas personas con las compañías.
                    </p>
                </li>
                <li className="font-semibold mt-10">
                    <h3 className="text-xl mb-3">Principios</h3>
                    <p className="font-light">Las compañías se preocupan por que toda persona tenga el derecho a controlar la Información que comparte con terceros, así como el derecho a que ésta se utilice de forma apropiada. Por este motivo, en sus actividades regulares de negocio, las compañías respetan los principios establecidos en la legislación:</p> <br />
                    <ul className="!list-disc ml-10 font-light">
                        <li className="mb-3">Principio de legalidad: El tratamiento de la Información realizado por las compañías se realizará conforme a lo establecido en la Ley, demás normativa y jurisprudencia aplicable. Se encuentra prohibida la recopilación de información por medios fraudulentos, desleales o ilícitos será definida por medio de la Política de Seguridad de la Información, la cual también incluye una relación detallada de las responsabilidades asignadas a las distintas áreas de las compañías para el cumplimiento de la normativa de protección de datos personales.</li>
                        <li className="mb-3">Principio de consentimiento: Las compañías no podrán tratar Información si no cuentan con el consentimiento previo, expreso, inequívoco y libre de su titular, salvo las excepciones previstas en la Ley.</li>
                        <li className="mb-3">Principio de finalidad: La Información debe ser recopilada por las compañías para una finalidad determinada, explícita, lícita, legítima y comunicada al titular. El tratamiento de dichos datos no se extenderá a una finalidad distinta a la establecida de manera inequívoca como tal al momento de su recopilación o incompatible con aquella que motivó su obtención.</li>
                        <li className="mb-3">Principio de proporcionalidad: Todo tratamiento de la Información realizado por las compañías deberá ser adecuado, necesario, oportuno, relevante y no excesivo a la finalidad para la que la Información hubiese sido recopilada o a la naturaleza misma, de las categorías especiales de datos.</li>
                        <li className="mb-3">Principio de calidad: La Información que vaya a ser tratada por las compañías debe ser veraz, exacta, íntegra, precisa, completa, comprobable, clara, y, en la medida de lo posible, actualizada, necesaria, pertinente y adecuada respecto de la finalidad para la que fue recopilada. Dicha Información deberá conservarse de forma tal que se garantice su seguridad y solo por el tiempo necesario para cumplir con la finalidad del tratamiento. Se adoptarán todas las medidas razonables para que se supriman o rectifiquen sin dilación los datos personales que sean inexactos con respecto a los fines para los que se tratan.</li>
                        <li className="mb-3">Principio de seguridad: Las compañías y los terceros que actúen como encargados del tratamiento de bases de datos personales de las compañías deberán adoptar las medidas técnicas, organizativas y legales necesarias para garantizar la seguridad de la Información y proteger los datos personales frente a cualquier riesgo, amenaza o vulnerabilidad. Éstas deben ser apropiadas y acordes con el tratamiento que se vaya a efectuar y con la categoría de la Información que se trate.</li>
                        <li className="mb-3">Principio de nivel de protección adecuado: En el caso de que las compañías realicen transferencias internacionales de la Información deberá garantizar un nivel suficiente de protección para la Información que se vaya a tratar o por lo menos equiparable a lo establecido en la Ley.</li>
                        <li className="mb-3">Principio de Lealtad: El tratamiento de datos personales deberá ser leal, por lo que para los titulares debe quedar claro que se están recogiendo, utilizando, consultando o tratando de otra manera, datos personales que les conciernen, así como las formas en que dichos datos son o serán tratados. En ningún caso los datos personales podrán ser tratados a través de medios o para fines ilícitos o desleales.</li>
                        <li className="mb-3">Principio de Transparencia: El tratamiento de datos personales deberá ser transparente, por lo que toda información o comunicación relativa a este tratamiento deberá ser fácilmente accesible y fácil de entender y se deberá utilizar un lenguaje sencillo y claro. Las relaciones derivadas del tratamiento de datos personales deben ser transparentes y se rigen en función de las disposiciones contenidas en la presente Ley, su reglamento y demás normativa atinente a la materia.</li>
                        <li className="mb-3">Principio de pertinencia y minimización de datos personales: Los datos personales deben ser pertinentes y estar limitados a lo estrictamente necesario para el cumplimiento de la finalidad del tratamiento.</li>
                        <li className="mb-3">Confidencialidad: El tratamiento de datos personales debe concebirse sobre la base del debido sigilo y secreto, es decir, no debe tratarse o comunicarse para un fin distinto para el cual fueron recogidos, a menos que concurra una de las causales que habiliten un nuevo tratamiento conforme los supuestos de tratamiento legítimo señalados en esta ley. Para tal efecto, el responsable del tratamiento deberá adecuar las medidas técnicas organizativas para cumplir con este principio.</li>
                        <li className="mb-3">Principio de conservación: Los datos personales serán conservados durante un tiempo no mayor al necesario para cumplir con la finalidad de su tratamiento. Para garantizar que los datos personales no se conserven más tiempo del necesario, el responsable del tratamiento establecerá plazos para su supresión o revisión periódica. La conservación ampliada de tratamiento de datos personales únicamente se realizará con fines de archivo en interés público, fines de investigación científica, histórica o estadística, siempre y cuando se establezcan las garantías de seguridad y protección de datos personales, oportunas y necesarias, para salvaguardar los derechos previstos en esta norma.</li>
                        <li className="mb-3">Principio de responsabilidad proactiva y demostrada: El responsable del tratamiento de datos personales deberá acreditar el haber implementado mecanismos para la protección de datos personales; es decir, el cumplimiento de los principios, derechos y obligaciones establecidos en la presente Ley, para lo cual, además de lo establecido en la normativa aplicable, podrá valerse de estándares, mejores prácticas, esquemas de auto y corregulación, códigos de protección, sistemas de certificación, sellos de protección de datos personales o cualquier otro mecanismo que se determine adecuado a los fines, la naturaleza del dato personal o el riesgo del tratamiento. El responsable del tratamiento de datos personales está obligado a rendir cuentas sobre el tratamiento al titular y a la Autoridad de Protección de Datos Personales. El responsable del tratamiento de datos personales deberá evaluar y revisar los mecanismos que adopte para cumplir con el principio de responsabilidad de forma continua y permanente, con el objeto de mejorar su nivel de eficacia en cuanto a la aplicación de la presente Ley.</li>
                        <li className="mb-3">Principio de aplicación favorable al titular: En caso de duda sobre el alcance de las disposiciones del ordenamiento jurídico o contractuales, aplicables a la protección de datos personales, los funcionarios judiciales y administrativos las interpretarán y aplicarán en el sentido más favorable al titular de dichos datos.</li>
                    </ul>
                </li>
            </ol>
        </div >
    )
}