import Button from '../../../components/Button';
import ButtonGradientTL from '../../../components/ButtonGradientTL';
import * as info from '../../../components/Info';

function FirstSection() {
  return (
    <div className="w-full min-h-screen pt-24 pb-10 lg:py-28 flex flex-col justify-center items-center bg-[#FAFAFA]">
      <div className='w-full max-w-screen-2xl grid grid-cols-1 lg:grid-cols-2 -mt-[85px] pt-5 lg:pt-[85px] bg-[#FAFAFA] px-5 lg:px-20'>
        <div className='col-span-1 flex flex-col justify-center order-2 lg:order-1'>
          <p className='text-sm'>-{info.sectionList[0]}-</p>
          <h2 className='w-full lg:w-3/4 font-lora font-semibold text-4xl lg:text-5xl leading-tight mt-5'>{info.firstSection.title}</h2>
          <p className='w-full lg:w-3/4 mt-10'>{info.firstSection.description}</p>
          <p className='w-full lg:w-3/4 mt-10'>{info.firstSection.description2}</p>
          <div className='w-full flex justify-center lg:justify-start items-center gap-5 lg:gap-10 mt-16'>
            <ButtonGradientTL title={info.firstSection.buttonLeft} />
            <Button title={info.firstSection.buttonRight} />
          </div>
        </div>
        <div className='col-span-1 flex flex-col justify-center items-center order-1 lg:order-2'>
          <img src={`/images/${info.firstSection.img}.svg`} alt={info.firstSection.img} />
        </div>
      </div>
    </div>
  );
}

export default FirstSection;
