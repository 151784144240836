import * as info from '../../../components/Info';
import SupportCard from '../../../components/SupportCard';

function ThirdSection() {
  return (
    <div className='w-full min-h-screen max-w-screen-2xl py-16 lg:py-20 grid grid-cols-12 px-5 lg:px-28'>
      <div className='col-span-12 lg:col-span-7 flex justify-start items-center relative mb-10 lg:mb-0'>
        <img src="/images/mobile.jpg" alt="mobile" className='w-2/3 lg:w-1/2' />
        <div className='w-60 lg:w-80 h-60 lg:h-80 absolute bottom-10 right-0 lg:right-28 border border-solid border-black bg-white rounded-2xl flex flex-col justify-center items-center px-5 text-xs lg:text-base'>
          <p className='w-full lg:w-2/3 text-center font-semibold text-base lg:text-xl leading-normal'>{info.thirdSection.titleCard}</p>
          {
            info.thirdSection.cardOption.map(item =>
              <div key={item.img} className='w-full flex justify-start items-center gap-3 lg:gap-5 mt-3 lg:mt-5'>
                <img src={`/images/${item.img}.svg`} alt={item.img} className='h-7 lg:h-11' />
                <span>{item.description}</span>
              </div>
            )
          }
        </div>
      </div>
      <div className='col-span-12 lg:col-span-5 flex flex-col justify-center items-center'>
        <p className='w-full text-sm'>-{info.sectionList[2]}-</p>
        <h2 className='font-lora font-semibold text-3xl lg:text-5xl leading-tight mt-5 mb-10'>{info.thirdSection.title}</h2>
        <div className='h-[45vh] lg:h-[50vh] overflow-y-scroll'>
          {
            info.thirdSection.supportList.map(item => <SupportCard key={item.title} item={item} />)
          }
        </div>
      </div>
    </div>
  );
}

export default ThirdSection;
