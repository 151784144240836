function ButtonBorderBL({ title }: { title: any }) {
    return (
        <div className="button-hover-bottomLeft relative">
            <button className='bottomLeft-bottom relative z-10 h-11 w-48 font-semibold text-sm border border-solid border-black rounded-xl bg-white'>
                {title}
            </button>
            <span className="bottomLeft-top absolute bg-green-gradient90 h-11 w-full top-2 -left-2 rounded-xl"></span>
        </div>
    );
}

export default ButtonBorderBL;
