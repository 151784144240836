import Slider from 'react-slick';
import * as info from '../../../components/Info';
import MembershipTypeCard from '../../../components/MembershipTypeCard';

function SeventhSection() {
  return (
    <div className='w-full min-h-screen max-w-screen-2xl py-16 lg:py-20 px-5 lg:px-28 grid grid-cols-12'>
      <div className='col-span-12 lg:col-span-7 flex flex-col justify-center items-start relative'>
        <p className='w-full text-sm'>-{info.sectionList[6]}-</p>
        <h2 className='w-full lg:w-5/6 font-lora font-semibold text-3xl lg:text-5xl leading-tight mt-5'>{info.seventhSection.title}</h2>
      </div>
      <div className='col-span-12 lg:col-span-5 flex flex-col justify-center items-center'>
        <p className='w-full lg:w-2/3 mt-10'>{info.seventhSection.description}</p>
      </div>
      <div className='col-span-12 relative mt-10 lg:mt-20'>
        <div className='w-full absolute bottom-[24.8%] hidden lg:flex flex-col justify-center items-start'>
          {
            info.seventhSection.options.map(item => <div key={item} className='w-full font-semibold even:bg-neutral-100 h-16 px-16 rounded-xl flex items-center'>{item}</div>)
          }
        </div>
        <div className='w-full hidden lg:grid grid-cols-3 relative'>
          <div className='col-span-1'></div>
          <div className='relative col-span-2 flex justify-center items-center gap-10 pr-5'>
            {
              info.seventhSection.membership.map(item => <MembershipTypeCard key={item.name} item={item} />)
            }
          </div>
        </div>
        <div className='w-full block lg:hidden gap-10 relative'>
          <Slider {...info.sliderSettingsMobile}>
            {
              info.seventhSection.membership.map(item => <MembershipTypeCard key={item.name} item={item} />)
            }
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default SeventhSection;
