import * as info from '../components/Info';

function MobileCustom() {
  return (
    <div className='w-full h-[100dvh] md:min-h-screen bg-white px-5 py-5 lg:p-5 flex flex-col justify-center items-center text-center' >
      <h2 className='w-full lg:w-1/2 font-lora font-semibold text-2xl lg:text-3xl leading-tight'>{info.customMobile.title}</h2>
      <p className='w-full lg:w-5/12 mt-10 lg:mt-5 text-sm'>{info.customMobile.description}</p>
      <div className='w-full lg:w-5/6 flex justify-center gap-10 my-10 lg:mt-10 lg:mb-0'>
        <a href={info.customMobile.appleLink}>
          <img src="/images/appStore.svg" alt="appStore" className='w-36' />
        </a>
        <a href={info.customMobile.googleLink}>
          <img src="/images/googlePlay.svg" alt="googlePlay" className='w-36' />
        </a>
      </div>
      <div className='w-full flex justify-center items-center relative'>
        <img src="/images/gif-doctor.gif" alt="gif-doctor" className='w-72 lg:w-96 relative z-10' />
        <img src="/images/circleCustom.svg" alt="circleCustom" className='w-72 lg:w-96 absolute' />
      </div>
    </div>
  );
}

export default MobileCustom;
