export const sectionList = [
    "BIENVENIDO",
    "NUESTRO SERVICIO",
    "PROTEGE TU NEGOCIO",
    "CÓMO FUNCIONA",
    "A TU MEDIDA",
    "FACILIDAD",
    "MEMBRESÍAS",
    "CLIENTES FELICES"
]

export const header = {
    logo: "logo",
    options: [
        {
            title: "Inicio",
            link: "/"
        },
        {
            title: "Nosotros",
            link: "#us"
        },
        {
            title: "Cómo funciona",
            link: "#how_works"
        },
        {
            title: "Precios",
            link: "#price"
        }
    ],
    login: "Iniciar sesión",
    signIn: "Regístrate"
}

export const firstSection = {
    title: "Consultoría legal para Negocios del siglo XXI",
    description: "Protege aquello por lo que estas trabajando con asesores legales experimentados, todo a través de un canal digital.",
    img: "davidHead",
    description2: "¿Qué estas esperando? Únete ahora y contáctate con un abogado en menos de 24 horas.",
    buttonLeft: "Inicia Gratis",
    buttonRight: "Regístrate"
}

export const secondSection = {
    title: "Súmate a la forma moderna de administrar los aspectos legales de tu empresa.",
    description: "Obtén la ayuda legal que necesitas, con nuestras herramientas DIY o el asesoramiento de nuestros abogados.",
    serviceList: [
        {
            title: "Consultas",
            description: "Realiza tus consultas a través de video, audio o texto.",
            img: "consultas",
            btnText: "Consultar"
        },
        {
            title: "Procesos y trámites",
            description: "Asistencia legal en trámites y procedimientos para garantizar plazos y reducir estrés del cliente.",
            img: "procesos",
            btnText: "Iniciar un proceso"
        },
        {
            title: "Documentos",
            description: "Creación de documentos legales personalizados para resolver problemas legales.",
            img: "documentos",
            btnText: "Crear un documento"
        }
    ]
}

export const thirdSection = {
    title: "Soporte integral para tu giro de negocio",
    titleCard: "¡Tu consultor te ha respondido!",
    cardOption: [
        {
            img: "video",
            description: "Mira la video respuesta de tu consultor"
        },
        {
            img: "document",
            description: "Recibe documentos de soporte"
        },
        {
            img: "share",
            description: "Compártelo con tus socios "
        }
    ],
    supportList: [
        {
            title: "Relaciones con empleados",
            description: "Administra y optimiza la relación con tus empleados."
        },
        {
            title: "Formaliza tu negocio",
            description: "Constituye tu compañía o conforma una asociación."
        },
        {
            title: "Propiedad Intelectual",
            description: "Protección legal de marcas, patentes y derechos de autor."
        },
        {
            title: "Contratos y cobranza",
            description: "Redacción y revisión de contratos y procesos de cobranza."
        }
    ]
}

export const quarterSection = {
    title: "Un proceso fácil y rápido para resolver tus problemas",
    description: "Soluciona tus dificultades y preocupaciones de manera efectiva y sin complicaciones."
}

export const fifthSection = {
    title: "Una modalidad para cada tipo de negocio",
    description: "Opciones adaptadas a cada tipo de empresa o emprendimiento, permitiendo satisfacer sus necesidades y objetivos comerciales.",
    payUse: {
        title: "Paga por uso",
        description: "Solicita tus consultas y procesos de manera gratuita, recibe ofertas y selecciona a tu consultor favorito.",
        img: "payUse",
        btnText: "Empezar"
    },
    membership: {
        title: "Adquiere una membresía",
        description: "Obtén acceso a servicios legales exclusivos y asesoría continua con membresía de nuestro bufete de abogados.",
        img: "membership",
        btnText: "Empezar"
    },
    benefits: [
        {
            description: "Cada membresía incluye un número de consultas GRATIS.",
            black: "GRATIS",
        },
        {
            description: "Recibes hasta el 15% de descuento en todos tus procesos y trámites.",
            black: "15% de descuento",
        },
        {
            description: "Genera automáticamente documentos y contratos para respaldar tu empresa",
            black: "",
        }
    ]
}

export const sixthSection = {
    title: "Haz tus preguntas como te sientas más cómodo",
    description: "Siéntete libre de formular tus preguntas, ya sea a través de video, voz, texto o videollamada.",
    options: [
        {
            img: "recordVideo",
            text: "Graba un video"
        },
        {
            img: "recordVoice",
            text: "Graba una nota de voz"
        },
        {
            img: "recordText",
            text: "Escribe un texto"
        },
        {
            img: "recordCall",
            text: "Agenda una videollamada"
        }
    ]
}

export const seventhSection = {
    title: "Brinda a tu negocio un soporte permanente",
    description: "Una membresía brinda acceso a servicios legales exclusivos y asesoría personalizada de manera continua.",
    options: [
        "Consultas mensuales",
        "Revisiones de documentos",
        "Preguntas rápidas",
        "Videollamadas",
        "Documentos automáticos",
        "Descuento en trámites y procesos"
    ],
    membership: [
        {
            name: "Donatello",
            type: "Emprendedor",
            consults: 1,
            documents: 0,
            questions: 2,
            videoCalls: "NO",
            automaticDocuments: "Ilimitados",
            discount: 5,
            price: 49,
            btnText: "Contratar"
        },
        {
            name: "Da Vinci",
            type: "PYMES",
            consults: 2,
            documents: 1,
            questions: 3,
            videoCalls: "YES",
            automaticDocuments: "Ilimitados",
            discount: 10,
            price: 69,
            btnText: "Contratar"
        },
        {
            name: "Miguel Ángel",
            type: "Compañías",
            consults: 3,
            documents: 2,
            questions: 4,
            videoCalls: "YES",
            automaticDocuments: "Ilimitados",
            discount: 15,
            price: 99,
            btnText: "Contratar"
        },
    ]
}

export const eighthSection = {
    title: "Esto opinan nuestros clientes",
    description: "Testimonios de clientes satisfechos con nuestros servicios, para que conozcas su experiencia.",
    comment: 'comment',
    commentList: [
        {
            name: "Cristhian Acosta",
            description: "He podido encontrar rápidamente a un abogado y me han brindado un servicio de calidad. La recomiendo ampliamente.",
            company: "Moderno SAS",
            img: "commentUser"
        },
        {
            name: "Inti Guzman",
            description: "Excelente herramienta. Los abogados con los que he hablado han sido muy profesionales y atentos.",
            company: "Inriking",
            img: "commentUser"
        },
        {
            name: "Santi Chiriboga",
            description: "Me encanta, es muy fácil de usar y pude encontrar un abogado en muy poco tiempo. Además, los precios son muy razonables.",
            company: "Los Intratables SAS",
            img: "commentUser"
        },
        {
            name: "Renzo Malla",
            description: "He podido contactar a varios abogados y comparar precios y servicios. Estoy muy satisfecho con los resultados.",
            company: "RemaTech",
            img: "commentUser"
        },
        {
            name: "Adrian Díaz",
            description: "Estaba un poco escéptico sobre usar la aplicación, pero debo decir que me sorprendió gratamente.",
            company: "Info Addi",
            img: "commentUser"
        }
    ]
}

export const footer = {
    title: "¡Nos encantaría mantenernos en contacto contigo!",
    description: "Mantengámonos conectados para informarte sobre nuestras últimas noticias y servicios legales.",
    btnText: "Contáctanos",
    logo: "logo",
    subDescription: "Únete ahora y contáctate con un abogado en menos de 24 horas.",
    copy: "© 2022 CONSULTOPIA todos los derechos reservados.",
    copyLinks: [
        { title: "Privacidad", link: "/privacy" },
        { title: "Términos y Condiciones", link: "/privacy" },
    ],
    links: [
        {
            title: "Enlaces",
            options: [
                { title: "Inicio", link: "#" },
                { title: "Nosotros", link: "#" },
                { title: "Cómo funciona", link: "#" },
                { title: "Precios", link: "#" }
            ]
        },
        {
            title: "Nosotros",
            options: [
                { title: "Partners", link: "#" },
                { title: "Trabaja con nosotros", link: "#" },
            ]
        },
        {
            title: "Comunidad",
            options: [
                { title: "Blog", link: "#" },
                { title: "Podcast", link: "#" },
            ]
        }
    ],
    address: {
        title: "Dirección",
        options: [
            "Quito",
            "Oficina N°- 6-00, ubicada en el Edificio Rubio.  Avenida de los Shyris 37-313 y Telégrafo",
        ]
    },
    socials: [
        { title: "facebook", link: "#" },
        { title: "instagram", link: "#" },
        { title: "twitter", link: "#" },
        { title: "youtube", link: "#" }
    ]
}

export const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
};
export const sliderSettingsMobile = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};
export const meta = {
    title: 'CONSULTOPIA',
    description: 'Consultoría legal para Negocios del siglo XXI',
    meta: {
        charset: 'utf-8',
        name: {
            robots: 'noindex',
            googlebot: 'noindex'
        }
    }
}

export const customMobile = {
    title: "¡Descarga la aplicación y lleva contigo toda la asesoría legal que necesitas!",
    description: "Descarga la aplicación  para una experiencia única y conveniente. ¡No pierdas ni un momento más, únete a nuestra comunidad móvil!",
    appleLink:"#",
    googleLink: "#"
}